<template>
  <div :class="`region-not-available ${dynamicComponentClass}`">
    <div v-if="$isMobile" class="fixed-background"></div>
    <MenuAndLogo v-if="$isMobile && !inSignupFlow" :showReferral="true" referralIconBackgroundColor="#fff"></MenuAndLogo>
    <div class="page-title has-text-weight-semibold has-text-centered">{{title}}</div>
    <div class="region-not-available-wrap is-clear pb-6">
      <div class="box plain-shadow-on-desktop has-text-centered is-relative">
        <div class="section-title has-text-weight-semibold has-text-centered">We haven’t made it to you yet!</div>
        <div class="has-text-centered not-available-text" >
          <div v-if="fromResidency">{{dynamicMessage}}</div>
          <div v-else-if="!isCanadian">We’re coming to America! Complete your profile and we’ll email you when we’re ready to rock 🚀</div>
          <div v-else>
            <div>addy isn’t available in your region yet—</div>
            <div>but best believe you will be notified</div>
            <div>first thing when we are!</div>
          </div>
        </div>
        <div class="not-available-image has-text-centered">
          <img src="@assets/images/signup/region-not-available.png" alt="not-available-image">
          <div class="subscribe" v-if="isCanadian && !inSignupFlow">Please subscribe to our mailing list and we will alert you when addy is available in your region.</div>
        </div>
        <b-button type="is-primary" expanded rounded class="is-medium-height next" @click="toVerifyPage" v-if="inSignupFlow">Next</b-button>
      </div>
      <button class="button is-rounded is-primary is-pulled-right next" @click="redirect" v-if="!inSignupFlow">{{buttonText}}</button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import { embeddedInIos, isAmericanUser } from '@utils/common-methods/common'

export default {
  data() {
    return {
      title: '',
      fromResidency: false,
    }
  },
  components: {
    MenuAndLogo,
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    dynamicComponentClass() {
      const mobileClass = this.$isMobile ? 'is-mobile' : 'pt-6'
      const signupClass = this.inSignupFlow ? 'sign-up-flow' : ''
      return mobileClass.concat(' ', signupClass)
    },
    inSignupFlow() {
      return this.$route.name === 'regionNotAvailableForSignup'
    },
    dynamicMessage() {
      return `Unfortunately, only ${this.isCanadian ? 'Canadian' : 'American'} tax residents can invest in this property.`
    },
    buttonText() {
      return this.fromResidency ? 'Back' : 'Dashboard'
    },
    isCanadian() {
      return !isAmericanUser()
    },
  },
  created() {
    if (!this.inSignupFlow) this.getQueryParams()
  },
  methods: {
    getQueryParams() {
      this.title = this.$route.query.title
      this.fromResidency = this.$route.query.fromResidency
    },
    redirect() {
      if (this.$route.query.fromResidency) return this.$router.go(-1)
      if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
      this.$router.push('/dashboard')
    },
    toVerifyPage() {
      const email = decodeURIComponent(this.$route.query.email)
      const investor = localStorage.getItem('investor')
      // email is already verified if coming from the SSO selectProvince page
      const url = (investor && JSON.parse(investor).emailVerifiedAt) ? '/signup/profile/1' : `/signup/verify?email=${encodeURIComponent(email)}`
      this.$router.push(url)
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.region-not-available {
  min-height: $min-height-mobile-container;
  &.is-mobile {
    min-height: unset;
    .next {
      margin-bottom: unset;
    }
  }
  &.sign-up-flow {
    .region-not-available-wrap > .box {
      height: unset;
      padding: 20px 32px;
      .section-title {
        font-size: 32px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.03em;
      }
    }
    @media only screen and (min-width: $min-viewport-width) {
      .plain-shadow-on-desktop > button {
        max-width: 70%;
      }
      .region-not-available-wrap {
        padding-bottom: 40px;
      }
    }
    .next {
      width: 100%;
      margin-bottom: unset;
      margin: 0 auto;
    }
  }
}
.page-title {
  font-size: 32px;
  line-height: 38px;
}
.fixed-background {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 201px;
  background-color: #F0EEF8;
  border-radius: 0px 0px 24px 24px;
}
.region-not-available-wrap {
  padding: 12px 25px 0;
  > .box {
    height: 375px;
    border-radius: 12px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    padding: 20px 32px 0;
    margin-bottom: 28px;
    .section-title {
      font-size: 16px;
    }
    .subscribe,
    .not-available-text {
      padding-top: 18px;
      font-size: 16px;
      line-height: 19px;
    }
    .subscribe {
      position: absolute;
      bottom: 16px;
      left: 0;
      padding: 0 37px;
    }
    .not-available-image {
      margin-top: 28px;
      margin-bottom: 32px;
      img {
        width: 100px;
      }
    }
  }
  .next {
    font-weight: bold;
    width: 100px;
    height: 51px;
    margin-bottom: 3em;
  }
}
</style>
